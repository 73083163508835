
export default [
  {
    title: "Dashboard",
    route: "super-admin-dashboard",
    icon: "PieChartIcon",
  },
  {
    title: "Company",
    route: "company-management",
    icon: "HomeIcon",
  },
  {
    title: "Company Request",
    route: "company-request",
    icon: "ClipboardIcon",
  },
];
